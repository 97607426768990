@media (prefers-reduced-motion: no-preference) {
  .loader {
    animation: loader-spin infinite 8s linear;
  }
}

@keyframes loader-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
